// tostify progressbar won't work in Edge without below css
@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.project-container{
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  display: grid;
  // grid-template-rows: 5.4em auto 8.6em;  
  grid-template-rows: 3.1em auto 8.6em;
}

.Toastify__progress-bar {
  animation: Toastify__trackProgress linear 1;
}
.Toastify{
display:contents
}
