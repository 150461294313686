$white: #fff;
$black: #000;
$darkGoldenrod: #b8860b;
$goldenrod: #daa520;
$grey: #c5c5c5;
$goldPrimary: #e6ae22;
$doveGrey: #756e6e;
$mineShaft: #312f2f;
$solitude: #e9ecef;
$transition-animate: all ease 0.4s;
