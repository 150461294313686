.sign-in {
  button {
    color: white !important;
    font-family: Arial, Helvetica, sans-serif !important;
    letter-spacing: 1px;
    border-radius: 0px !important;
    &:hover {
      color: rgb(22, 19, 19) !important;
      font-weight: 600;
      border-color: black;
      background-color: goldenrod;
    }
  }
}
