// @import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import './variables';

html {
  height: 100%;
  body {
    overflow: hidden; // To resolve tour step for install button and canvas
    // background-color: #1a1a1a;
    .introjs-tooltip.customTooltip.introjs-left {
      top:40%;
    }
    .introjs-tooltip{
      min-width: 450px;
    }
    .introjs-tooltip.introjs-floating{
      margin-left: 0px;
    }
    // intro arrow bigger change from 5 to 8
    .introjs-arrow{
      border: 8px solid transparent;
    }
    .introjs-arrow.top, .introjs-arrow.top-right  {
      top: -16px;
    }
    .introjs-arrow.left, .introjs-arrow.left-bottom{
      left: -16px;
    }
    .introjs-arrow.right{
      right: -16px;
    }
    .introjs-arrow.bottom{
      bottom: -16px;
    }

    #wpadminbar{
      display: none;
    }
    height: 100%;
    #root {
      height: 100%;
      .app {
        padding-top: 86px;
        &.iframe-page{
            padding-top: 65px;
          }
        height: 100%;
        @media only screen and (max-width: 991px) {
          &.iframe-page{
            padding-top: 57px;
          }
        }
        &.pro {
          padding-top: 50px;
        }
        text-align: center;
        overflow-x: hidden; 
        /* Hide horizontal scrollbar */

        span {
          &.has-error {
            color: red;
          }
        }

        .container {
          .mt-5 {
            &.separator {
              height: 1px;
              width: 80%;
              background-color: $black;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

.bg-white {
  background-color: $white;
}

a, .btn, button {
  transition: $transition-animate;
  outline: 0;
}
a, button {
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}
img {
  max-width: 100%;
}

.sub-btn {
  color: $goldPrimary;
  border: 1px solid $goldPrimary;
  background: transparent;
  svg {
    color: $goldPrimary;
    transition: $transition-animate;
  }
  &:hover {
    background: $goldPrimary;
    color: $black;
    border-color: $goldPrimary;
    svg {
      color: $black;
    }
  }
  &:focus {
    box-shadow: none;
    background: $goldPrimary;
    color: $black;
    border-color: $goldPrimary;
    svg {
      color: $black;
    }
  }
}

.download-button{
  background: $goldPrimary;
  border-color: $goldPrimary;
  padding: 10px 16px;
}

.modal {
  .modal-header {
    .close {
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 45px;
      display: inline-block;
      background: $goldenrod;
      opacity: 1;
      line-height: 0;
      padding: 0;
      font-size: 26px;
      color: $black;
      text-shadow: none;
      z-index: 9;
      &:hover {
        background: $darkGoldenrod;
        opacity: 1;
      }
    }
  }
  .modal-content {
    padding: 10px;
    .tab-content {
      ul {
        white-space: pre-line;
        padding: 0;
        li {
          display: inline-block;
          margin: 5px;
          position: relative;
          padding-left: 10px;
          &:before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 10px;
            background-color: $black;
          }
        }
      }
    }
    a{
      color: $darkGoldenrod;
      &:hover{
        color: $goldPrimary;
      }
    }
  }
  iframe, video {
    width: 100%;
    line-height: 0;
    border: 0;
  }
}

$position-map: (
  top: 't',
  right: 'r',
  left: 'l',
  bottom: 'b',
);

$unit-map: (
  px: 'px'
);

@for $size from 0 through 100 {
  @each $unit, $ut in $unit-map {
    @each $position, $pos in $position-map {
      .m-#{$pos}--#{$size}#{$unit} {
        margin-#{$position}: #{$size}#{$ut};
      }
      .p-#{$pos}--#{$size}#{$unit} {
        padding-#{$position}: #{$size}#{$ut};
      }
    }
  }
}

// .list-circle {
//   ul {
//     list-style: none;
//     margin: 0;
//     padding: 0;
//     li {
//       display: inline-block;
//       color: $black;
//       font-size: 16px;
//       text-align: left;
//       width: 100%;
//       padding-left: 20px;
//       letter-spacing: 0.29px;
//       position: relative;
//       line-height: 24px;
//       margin: 0 0 9px;
//       &:before {
//         content: '';
//         border-radius: 50%;
//         width: 10px;
//         height: 10px;
//         display: inline-block;
//         border: 2px solid $goldenrod;
//         position: absolute;
//         left: 0;
//         top: 8px;
//       }
//       &:last-child {
//         margin: 0;
//       }
//       @media only screen and (max-width: 991px) {
//         font-size: 15px;
//       }
//     }
//   }
// }

.managerModal {
  .logo-size{
    max-width: 164px;
    width: 100%;
  }
  .title-size{
    max-width: 65px;
    width: 100%;
  }
}

.iframeOuter{
  iframe{
    height: calc(100vh - 65px);
    border: 0;
    @media (max-width: 991px) {
      height: calc(100vh - 57px);
    }
  }
}

.iframe-page{
  overflow: inherit !important;
  .hide-nav {
    height: 65px !important;
    .headroom {
      .header-nav {
        &.navbar {
          height: 65px;
        }
      }
    }
    @media (max-width: 991px)  {
      height: 57px !important;
      .headroom {
        .header-nav {
          &.navbar {
            height: 57px;
          }
        }
      }
    }
  }
}