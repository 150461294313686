@import '../../styles/variables';

.dot {
  height: 30px;
  width: 30px;
  background-color: #ced4da;
  border-radius: 50%;
  display: inline-block;
  font-weight: 500;
  color: black;
}
.header-menu.popover-body{
    padding: 5px 0px;
    font-size: 1rem;
    font-weight: 400;
  }
  .header-menu.popover-body a,.header-menu.popover-body button{
   padding: 0.25rem 2.5rem;
  }
  
  .header-menu .active-header-link, .header-menu .dropdown-item.active-header-link,.header-menu .dropdown-item.active, .dropdown-item:active{
    background-color: goldenrod;
    color: #16181b;
    &:hover{
      color: #16181b;
      background-color: #e9ecef;
    }
  }
  .dropdown-item.active, .dropdown-item:active{
    background-color: goldenrod !important;
    color: #16181b;
    &:hover{
      color: #16181b;
      background-color: #e9ecef;
    }
  }
  
  .language-section{
    display: flex;
    justify-content: center;
    cursor: pointer;
    &:hover{
      color: #16181b ;
      background-color: #efedea;
    }
  }
  .lang-menu .arrow{
    display: none !important; 
  }
  .active-language{
    background-color: goldenrod;
    color: white;
  }

  .headroom-wrapper {
    .pro-nav{
      .dropdown-toggle.nav-link{
        padding:0px;
        .nav-link{
          padding: 8px 12px !important;
        }
      }
    }
    
    .userInfo{ 
      a{
          padding: 0;
          margin: 0px;
          .user-profile-head{
            padding: 0.5rem 1rem;
          }
      }
      .dropdown-menu.show{
        a{
          margin: 0px;
          padding: 0.25rem 1rem;
        }
      }
    }

    /*navbar*/
    .dropdown-toggle::after{
        display: none !important;
    }
  grid-row: 1 / auto;
  justify-self: center;
  align-self: center;

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  &.hide-nav {
    height: 50px;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    .headroom {
      @media (min-width: 992px)  {
        .nav-item.dropdown:hover .dropdown-menu {
          display: block;
        }
      }
      .header-nav {
        &.navbar {
          padding: 0.3rem 1rem;
          justify-content: space-between;
        }
          .navbar-brand {
          img {
            width: 30px;
            height: 30px;
          }
        }
        .navbar-brand {
          margin: 0px;
          padding: 0.5rem 1rem;
        }
      }
    }
  }


@media (min-width: 992px)  {
  &.hide-nav.app-nav {
    .headroom {
      .header-nav {
        &.navbar {
          height: 65px;
        }
      }
    }
  }
  
  .navbar-brand.brand {
    img {
      height: 3vw;
      width: 3vw;
    }
  }
  .userInfo{
    .dropdown-menu.show{
      left: -90px;
    }
  }
}
  .header-nav {
    background-color: #111;
    .navbar-nav {
      a {
        &.items {
          &.nav-link {
            color: white;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 20px;
            &.active {
              color: #e6ae22;
            }
            img {
              max-width: 40px;
            }
          }
        }
      }
    }

    .navbar-brand {
      img {
        width: 115px;
        height: 60px;
      }
    }

    .signIn {
      .globe {
        margin-right: 6px;
      }
    }

    .nav-item {
      &.dropdown {
        a.dropdown-toggle {
          color: $white;
        }
      }
    }

    .sign-in-text {
      color: $white;
    }

    .select-language{
      background: #343434;
      color: #fff;
      border-color: #343434;
      cursor: pointer;
      background-image: url(../../assets/contact-select.png);
      background-repeat: no-repeat;
      background-position: right center;
      &:focus{
        box-shadow: none;
      }
    }
    .language-selector{
      width: 100px;
      padding:0px;
      cursor: pointer;
      .css-yk16xz-control,.css-1pahdxg-control{
        background: #343434;
        border-color: #343434;
        color: #fff;
        box-shadow: none;
        cursor: pointer;
        .css-1uccc91-singleValue{
          color: #fff;
        }
        .css-1okebmr-indicatorSeparator{
          background: #424242;
        }
        .css-1gtu0rj-indicatorContainer{
          &:hover{
            color: #fff !important;
          }
        }
      }

      .css-26l3qy-menu{
        text-align: left;
        overflow: hidden;
        background: transparent;
        .css-4ljt47-MenuList, .css-11unzgr{
          padding: 0;
          background-color: #e3e3e3;
          .css-1n7v3ny-option{
            background-color: #e3e3e3;
            cursor: pointer;
          }
          .css-9gakcf-option{
            background: $goldPrimary;
            cursor: pointer;
          }
        }
      }
    }
  }

  /* NavItem ActiveClass*/

  .toggle {
    &.navbar-toggler {
      background-color: rgb(119, 114, 114);
    }
  }

  /*icons*/
  .icons {
    color: white;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 10px;
  }

  /*globe*/
  .globe {
    width: 23px;
    height: 23px;
    background: url(../../assets/globe.gif) no-repeat center center;
    border-radius: 100%;
    background-size: 19px;
    animation: spin 6s infinite linear;
    border: 1px solid white;
    margin-top: 17px;
    /* animation: rotation 6s linear 0s infinite; */
    margin-right: 15px;
    margin: auto;
  }

  /*header hide and show*/
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
}

.modal-footer {
  border-top: 0px !important;
}

.download-button {
  padding: 8px 8px 8px 8px;
  margin: 10px;
}
.logo-size {
  width: 40%;
}
.title-size {
  width: 40%;
}

.signIn {
  display: inline-block !important;
  .login-btn {
    background: transparent;
    border: 1px solid white;
    color: white;

    &:hover {
      border-color: goldenrod;
      color: goldenrod;
      background: transparent;
    }
  }
}

// Changes
.headroom-wrapper{
  .header-nav{

    .bran-menu{
      .nav-link{
        padding: 0;
        .navbar-brand{
          padding: 0.5rem 1rem;
          width: 100%;
        }
      }
    }


    &.navbar-light{
      > .header-menu{
        margin-left: 0 !important;
      }
    }

    .header-menu{
      &:hover{
        @media (min-width: 991px)  {
          &.nav-item{
            margin: 0 12px 0 12px;
            min-width: 160px;
            transition: $transition-animate;
          }
          a.nav-link{
            .gim-logo{
              filter: brightness(0) invert(0.4)
            }
            color: #5d5d5d;
            &.active{
              color: #5d5d5d;
            }
          }
        }
      }

      &.nav-item{
        margin: 0 10px;
        min-width: inherit;
        @media (max-width: 991px)  {
          margin: 0;
        }
        .nav-link{
          position: relative;
          z-index: 10;
          text-align: left;
        }
        .dropdown-menu{
          border-radius: 20px;
          padding-top: 40px;
          padding-bottom: 0;
          z-index: 9;
          top: 0;
          left: -5px;
          border: 2px solid #707072;
          overflow: hidden;
          // transition: $transition-animate;
          .dropdown-item{
            padding: 0.25rem 1rem;
            transition: none;
            &:last-child{
              padding-bottom: 0.8rem;
            }
            &:hover{
              background: goldenrod;
            }
          }


          @media (max-width: 991px)  {
            padding-top: 0;
            top: 100%; 
            z-index: 100;
            .dropdown-item{
              &:first-child{
                padding-top: 0.8rem;
              }
            }
          }

        }
      }

      @media (min-width: 992px)  {
        &.first-child-active{
          .dropdown-menu{
            background: $goldenrod;
            .dropdown-item{
              background: white;
              &.active-header-link{
                background: goldenrod;
                color: $black;
              }
            }
          }
        }
      }
      @media (max-width: 991px)  {
        .mobiMenuItem{
          display: flex;
          justify-content: space-between;
          padding: 8px 0;
          align-items: center;
          
          .text{
            padding: 8px 0;
            &.active{
              color: $goldenrod;
            }
          }
          .icon{
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            flex-shrink: 0;
            background: #0F0F0E;
            border-radius: 6px;
            position: relative;
            z-index: 2;
          }
        }

      }

    }

    .userInfo{
      .user-profile{
        &:hover{
          @media (min-width: 992px)  {
            min-width: 150px;
            margin: 0 4px;
            transition: $transition-animate;
          }
        }
        .dropdown-menu{
          border-radius: 20px;
          padding-top: 42px;
          padding-bottom: 0;
          z-index: 9;
          top: -5px;
          left: auto;
          right: -5px;
          border: 2px solid #707072;
          overflow: hidden;
          // transition: $transition-animate;
          .dropdown-item{
            padding: 0.3rem 1rem;
            margin: 0;
            transition: none;
            &:last-child{
              padding-bottom: 0.8rem;
              border-radius: 0;
            }
            &:hover{
              background: goldenrod;
            }
          }


          @media (max-width: 991px)  {
            padding-top: 0;
            top: 100%; 
            z-index: 99;
            .dropdown-item{
              &:first-child{
                padding-top: 0.8rem;
              }
            }
          }

        }
        &.nav-item{
          > .nav-link{
            position: relative;
            z-index: 10;
          }
        }

        &.user-active-link{
          .dropdown-menu{
            background: $goldenrod;
            .dropdown-item{
              background: white;
              &.user-active-link{
                background: goldenrod;
              }
            }
          }
        }

        .dropdown-divider{
          background: white;
          margin: 0;
        }
      }
    }

    .navbar-collapse{
      @media (max-width: 991px) {
        position: fixed;
        top: 57px;
        left: 0;
        padding: 20px;
        z-index: 99;
        background-color: #161616;
        width: 100%;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.1);
        height: calc(100vh - 57px)!important;
        overflow-y: auto;
        transition: all ease 0.4s;

        .navbar-nav{
          .nav-item{
            border-bottom: 1px solid #303030;
            .dropdown-menu{
              background: #222222;
              border-radius: 0;
              border: none;     
              width: 100%;
              .dropdown-item{
                color: white;
                padding: 10px 12px !important;
                border-bottom: 1px solid #2c2c2c;
                &:last-child{
                  border: none;
                }
              }       
            }

            &.show {
              .mobiMenuItem .icon{
                svg{
                  transform: rotate(-180deg);
                }
              }
            }
          }
        }
      }
    }

    .headRightBar{
      .globe-button{
        padding: 2px;
        margin: 0.5rem 1rem;
      }
    
      @media (max-width: 991px) {
        margin-left: auto;
        .globe-button{
          margin: 0.3rem 0;
          padding: 5px;
        }
        .download-exe{
          span{
            display: none;
          }
        }
      }
    }

  }
}



.lang-menu.popover{
    border-radius: 20px;
    padding-bottom: 0;
    z-index: 99;
    border: 2px solid #707072;
    overflow: hidden;
    .header-menu{
      &.popover-body{
        padding: 0;
        .language-section{
          border-right: 1px solid #efedea;
          border-bottom: 1px solid #efedea;
          margin: 0 -1px -1px 0;
        }
      }
    }
}